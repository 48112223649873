// COOKIE BANNER
const Cookies = require('js-cookie');

document.addEventListener("DOMContentLoaded", function(event){
  processCookieConsent();
});

const cookieBanner = document.getElementById('cookie-banner');
const cookieInformAndAsk = document.getElementById('cookie-inform-and-ask');
const cookieAcceptButton = document.getElementById('cookie-accept-button');
const cookieMoreButton = document.getElementById('cookie-more-button');
const gaCancelButton = document.getElementById('ga-cancel-button');
const gaConfirmButton = document.getElementById('ga-confirm-button');
const gaCancelButtonOnPrivacyPage = document.getElementById('ga-cancel-button-on-privacy');
const gaConfirmButtonOnPrivacyPage = document.getElementById('ga-confirm-button-on-privacy');
const gaStatusPrivacyPage = document.getElementById('ga-status-on-privacy');
const gaStatusPrivacyPageCancel = document.getElementById('ga-status-on-privacy-cancel');
const gaStatusPrivacyPageConfirm = document.getElementById('ga-status-on-privacy-confirm');

const GA_PROPERTY = 'UA-113297846-1'
const GA_COOKIE_NAMES = ['__utma', '__utmb', '__utmc', '__utmz', '_ga', '_gat'];
const GA_DISABLE_COOKIE_NAME = `ga-disable-${GA_PROPERTY}`;
const AT_COOKIE_NAMES = ['__atuvc', '__atuvs'];

function processCookieConsent() {
  const consentCookie = Cookies.getJSON('hasConsent');
  const doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack;

  // 1. On active les button GA s'ils sont présent sur la page
  if(gaStatusPrivacyPageConfirm){
    gaStatusPrivacyPageUpdate();
    gaCancelButtonOnPrivacyPage.addEventListener('click', onGaCancelButtonOnPrivacyPageClick, false);
    gaConfirmButtonOnPrivacyPage.addEventListener('click', onGaConfirmButtonOnPrivacyPageClick, false);
  }

  // 3. Si le cookie existe et qu'il vaut explicitement "false" ou que le "doNotTrack" est défini à "OUI"
  //    l'utilisateur s'oppose à l'utilisation des cookies. On exécute une fonction spécifique pour ce cas.
  if (doNotTrack === 'yes' || doNotTrack === '1' || consentCookie === false) {
    rejectGoogleAnalytics();
    return;
  }

  // 4. Si le cookie existe et qu'il vaut explicitement "true", on démarre juste Google Tag Manager
  if (consentCookie === true) {
    startGoogleTagManager();
    return;
  }

  // 5. Si le cookie n'existe pas et que le "doNotTrack" est défini à "NON", on crée le cookie "hasConsent" avec pour
  //    valeur "true" pour une durée de 13 mois (la durée maximum autorisée) puis on démarre Google Analytics
  if (doNotTrack === 'no' || doNotTrack === '0') {
    setCookieConsent();
    startGoogleTagManager();
    return;
  }

  // 6. Si le cookie n'existe pas et que le "doNotTrack" n'est pas défini, alors on affiche le bandeau et on crée les listeners
  cookieBanner.classList.add('active');
  cookieAcceptButton.addEventListener('click', onAcceptButtonClick, false);
  cookieMoreButton.addEventListener('click', onMoreButtonClick, false);
  gaCancelButton.addEventListener('click', onGaCancelButtonClick, false);


  // 8. Si le visiteur continue la navigation, il conscent par défaut
  document.addEventListener('click', onDocumentClick, false);
}

function onDocumentClick(event) {
  const target = event.target;

  // Si l'utilisateur a cliqué sur le bandeau ou le bouton dans ce dernier alors on ne fait rien.
  if (target.id === 'cookie-banner'
    || target.parentNode.id === 'cookie-banner'
    || target.parentNode.parentNode.id === 'cookie-banner'
    || target.id === 'cookie-more-button'
    || target.id === 'ga-cancel-button'
    || target.id === 'ga-confirm-button'
    || target.id === 'addthi-opt-out'
    || target.id === 'cookie-accept-button') {
    return;
  }

  setCookieConsent();
  startGoogleTagManager();
  hideBanner();
  hideDialog();

  removeBannerListeners();
}

function setCookieConsent(){
  Cookies.set('hasConsent', true, { expires: 365 });
}

function hideBanner(){
  cookieBanner.className = cookieBanner.className.replace('active', '').trim();
}

function hideDialog(){
  cookieInformAndAsk.className = cookieBanner.className.replace('active', '').trim();
}

function removeBannerListeners(){
  document.removeEventListener('click', onDocumentClick, false);
  cookieMoreButton.removeEventListener('click', onMoreButtonClick, false);
  cookieAcceptButton.removeEventListener('click', onAcceptButtonClick, false);
  gaCancelButton.removeEventListener('click', onGaCancelButtonClick, false);
}


function onAcceptButtonClick(event){
  event.preventDefault();

  setCookieConsent();
  startGoogleTagManager();
  hideBanner();

  removeBannerListeners();
}

function onMoreButtonClick(event) {
  event.preventDefault();

  // On affiche la boîte de dialogue permettant à l'utilisateur de faire son choix
  cookieInformAndAsk.classList.add('active');


  hideBanner();

  // On crée les listeners sur les boutons de la boîte de dialogue
  gaCancelButton.addEventListener('click', onGaCancelButtonClick, false);
  gaConfirmButton.addEventListener('click', onGaConfirmButtonClick, false);
}

function onGaConfirmButtonClick(event) {
  event.preventDefault();

  setCookieConsent();
  startGoogleTagManager();

  hideDialog();

  // On supprime les listeners sur les boutons de la boîte de dialogue
  gaCancelButton.removeEventListener('click', onGaCancelButtonClick, false);
  gaConfirmButton.removeEventListener('click', onGaConfirmButtonClick, false);
}

function onGaCancelButtonClick(event) {
  event.preventDefault();

  // On lance la procédure de refus de l'utilisation des cookies
  rejectGoogleAnalytics();
  hideBanner();
  hideDialog();

  // On supprime les listeners sur les boutons de la boîte de dialogue
  gaCancelButton.removeEventListener('click', onGaCancelButtonClick, false);
  gaConfirmButton.removeEventListener('click', onGaConfirmButtonClick, false);
  cookieMoreButton.removeEventListener('click', onMoreButtonClick, false);
  cookieAcceptButton.removeEventListener('click', onAcceptButtonClick, false);
}

function onGaCancelButtonOnPrivacyPageClick(event) {
  event.preventDefault();

  rejectGoogleAnalytics();

  gaStatusPrivacyPageUpdate();
}

function onGaConfirmButtonOnPrivacyPageClick(event) {
  event.preventDefault();

  setCookieConsent();
  startGoogleTagManager();

  gaStatusPrivacyPageUpdate();
}

function startGoogleTagManager() {
  document.addEventListener('turbolinks:load', function(event) {
    var url = event.data.url;  dataLayer.push({
      'event':'pageView',
      'virtualUrl': url
    });
  });
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-P7JKNZV');
  removeGoogleAnalyticsDisableCookie();
}

function rejectAddThis(){
  AT_COOKIE_NAMES.forEach(cookieName => Cookies.remove(cookieName));
}

function rejectGoogleAnalytics() {
  // création du cookie spécifique empêchant Google Analytics de démarrer
  Cookies.set(GA_DISABLE_COOKIE_NAME, true, { expires: 395 });
  // insertion de cette valeur dans l'objet window
  window[GA_DISABLE_COOKIE_NAME] = true;

  // création du cookie précisant le choix utilisateur
  Cookies.set('hasConsent', false, { expires: 395 });

  // suppression de tous les cookies précédemment créés par Google Analytics
  GA_COOKIE_NAMES.forEach(cookieName => Cookies.remove(cookieName));
}

function removeGoogleAnalyticsDisableCookie(){
  Cookies.remove(GA_DISABLE_COOKIE_NAME);
  window[GA_DISABLE_COOKIE_NAME] = false;
}

function gaStatusPrivacyPageUpdate(){
  if(gaStatusPrivacyPageConfirm){
    const consentCookie = Cookies.getJSON('hasConsent');
    const doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack;

    if (doNotTrack === 'yes' || doNotTrack === '1' || consentCookie === false) {
      gaStatusPrivacyPageConfirm.classList.remove('show');
      gaStatusPrivacyPageCancel.classList.add('show');
      gaCancelButtonOnPrivacyPage.disabled = true;
      gaConfirmButtonOnPrivacyPage.disabled = false;
    } else {
      gaStatusPrivacyPageConfirm.classList.add('show');
      gaStatusPrivacyPageCancel.classList.remove('show');
      gaConfirmButtonOnPrivacyPage.disabled = true;
      gaCancelButtonOnPrivacyPage.disabled = false;
    }
  }
}
